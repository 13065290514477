import React from "react";
import rehypeReact from 'rehype-react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { AboutItem, ExternalLink, Typography, WrapperDiv } from '../components/common';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const AboutInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 500px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const AboutHeading = styled(Typography)`
  @media (max-width: 500px) {
    border-bottom: 1px solid gray;
  }
`;

const AboutWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const renderCustom = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const AboutPage = () => {
  const { about } = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "about.md" }) {
        childMarkdownRemark {
          frontmatter {
            h1
          }
          htmlAst
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="About" />
      <Typography variant="h1" dangerouslySetInnerHTML={{ __html: about.childMarkdownRemark.frontmatter.h1 }}></Typography>
      <WrapperDiv fontSize="16" variant="p">
        {renderCustom(about.childMarkdownRemark.htmlAst)}
        <Typography fontSize="16" variant="p">
          Feel free to contact me: <ExternalLink color="red" href="mailto:mail@pedroporto.com.pt">mail@pedroporto.com.pt</ExternalLink>
        </Typography>
      </WrapperDiv>
      <AboutWrapper>
        <AboutInfo>
          <AboutHeading fontSize="20" transform="uppercase" variant="h3">
            Education
          </AboutHeading>
          <AboutItem title="FLAG" timeline="Web Developer 2016 – 2016" />
          <AboutItem title="ARQUI 300 Academy" timeline="3ds Studio Max Advanced (V-RAY) 2010 – 2011" />
          <AboutItem title="ACADEMUS" timeline="2010-2010" />
          <AboutItem title="EPRAL" timeline="Multimeedia - 2007-2010" />
        </AboutInfo>
        <AboutInfo>
          <AboutHeading transform="uppercase" fontSize="20" variant="h3">
            Experience
          </AboutHeading>
          <AboutItem
            upper
            position="Web Developer"
            title="Mercedes-Benz.IO"
            timeline="2020 – current"
          />
          <AboutItem
            upper
            position="Web Developer"
            title="Twistag"
            timeline="2018 – 2020"
          />
          <AboutItem
            position="Web Developer"
            title="ActiveMedia"
            timeline="2016 – 2018"
          />
          <AboutItem
            position="Lights & Sound Technician "
            title="O Espaço do Tempo"
            timeline="2014 – 2016"
          />
          <AboutItem
            position="Web Designer"
            title="Herdade do Pico"
            timeline="2013 – 2014"
          />
          <AboutItem
            position="Multimedia Teacher "
            title="Epral"
            timeline="2011 - 2012"
          />
        </AboutInfo>
      </AboutWrapper>
    </Layout>
  )
}

export default AboutPage;